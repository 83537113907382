<template>
	<div>
		<span class="user-agreement__info">
			Нажимая «Получить код», я соглашаюсь и принимаю условия:
			<template v-if="!isMobile">
				<a href="/policies/offer-nao-vkusnoibistro/">пользовательского соглашения АО «Вкусно и Быстро»</a>,
				<a href="/policies/offer-seller/">пользовательского соглашения Продавца еды</a>,
				<a href="/policies/offer-nao-suncoin/">правила и условия программы лояльности</a>
			</template>
			<template v-else>
				<a
					href="javascript:void(0);"
					@click="showTerms('agreement-vib')"
				>
					пользовательского соглашения АО «Вкусно и Быстро»
				</a>,
				<a
					href="javascript:void(0);"
					@click="showTerms('agreement-seller')"
				>
					пользовательского соглашения Продавца еды
				</a>,
				<a
					href="javascript:void(0);"
					@click="showTerms('loyalty')"
				>
					правила и условия программы лояльности
				</a>
			</template>
		</span>
		<Popup :visible.sync="isTermsModalVisible">
			<Terms
				:terms-text-mode="dataTermsTextMode"
				@close="isTermsModalVisible = false"
			/>
		</Popup>
	</div>
</template>
<script>
export default {
	name: "UserAgreementLinks",
	components: {Terms: (() => import('@/components/popup/Terms.vue'))},
	data: () => ({
		isMobile: !!process.env.VUE_APP_MOBILE,
		isTermsModalVisible: false,
		dataTermsTextMode: "",
	}),
	methods: {
		showTerms(term)
		{
			this.dataTermsTextMode = term;
			this.isTermsModalVisible = true;
		},
	}
}
</script>
<style lang="scss">
.user-agreement__info
{
	font-family: $mainFontNew;
	font-weight: 400;
	font-size: 14px;
	line-height: 143%;
	text-align: center;
	color: $black;
}

.user-agreement__info a
{color: $green;}
</style>