<template>
	<ValidationProvider
		v-slot="{ errors, reset, validate }"
		:rules="rules"
		mode="passive"
		tag="div"
		class="ui-input__wrapper"
	>
		<div :class="['ui-input', (error || errors[0]) && 'ui-input__error']">
			<input
				v-maska="mask"
				:value="value"
				:class="['ui-input__input', placeholder && 'ui-input__input-placeholder']"
				:id="id"
				:name="name"
				:type="type"
				:placeholder="placeholder"
				:disabled="disabled"
				@input="emitChange"
				@change="validate"
				@focus="clearError(); reset()"
			>
			<label
				:class="['ui-input__label', value && 'ui-input__label-active']"
				:for="id"
			>
				{{ label }}
			</label>
			<img
				@click="handleChangeType"
				src="~@/assets/img/eye.svg"
				alt="Eye"
				class="ui-input__input-clear"
			/>
		</div>
		<span class="ui-input__error-text">{{ error || errors[0] }}</span>
	</ValidationProvider>
</template>
<script>
export default {
	name: "UiPasswordInput",
	props: {
		rules: {
			type: [Object, String],
			default: ''
		},
		id: {
			type: String,
			default: '',
		},
		value: {
			type: String,
			default: ''
		},
		label: {
			type: String,
			default: ''
		},
		name: {
			type: String,
			default: ''
		},
		error: {
			type: String,
			default: ''
		},
		placeholder: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		mask: {
			type: [String, Object],
			default: ''
		}
	},
	emits: ['input'],
	data()
	{
		return {
			type: 'password'
		}
	},
	methods: {
		emitChange(e)
		{
			this.$emit('input', e.target.value)
		},
		handleChangeType()
		{
			this.type = this.type === 'password' ? 'text' : 'password';
		},
		clearError()
		{
			this.$emit('clearError');
		}
	}
}
</script>