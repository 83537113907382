<template>
	<div class="ui-input__wrapper">
		<div :class="['ui-input', error && 'ui-input__error']">
			<input
				v-maska="mask"
				:value="value"
				:class="['ui-input__input', placeholder && 'ui-input__input-placeholder']"
				:id="id"
				:name="name"
				:type="type"
				:placeholder="placeholder"
				:disabled="disabled"
				@focus="clearError"
				@input="emitChange"
			>
			<label
				:class="['ui-input__label', value && 'ui-input__label-active']"
				:for="id"
			>
				{{ label }}
			</label>
			<img
				v-show="value"
				@click="!disabled && clearInput"
				src="~@/assets/img/close.svg"
				alt="Clear"
				class="ui-input__input-clear"
			/>
		</div>
		<span class="ui-input__error-text">{{ error }}</span>
	</div>
</template>
<script>
export default {
	name: "UiInput",
	props: {
		id: {
			type: String,
			default: '',
		},
		value: {
			type: String,
			default: ''
		},
		label: {
			type: String,
			default: ''
		},
		name: {
			type: String,
			default: ''
		},
		type: {
			type: String,
			default: 'text'
		},
		error: {
			type: String,
			default: ''
		},
		placeholder: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		mask: {
			type: [String, Object],
			default: ''
		}
	},
	emits: ['input'],
	methods: {
		emitChange(e)
		{
			this.$emit('input', e.target.value)
		},
		clearInput()
		{
			this.$emit('input', '')
			this.$emit('clearError');
		},
		clearError()
		{
			this.$emit('clearError');
		}
	},
}
</script>