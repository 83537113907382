<template>
	<div class="cart-order-info">
		<img
			src="~@/assets/img/icons/point.svg"
			alt="Point"
		>
		<div class="cart-order-info__address">
			<h3 class="cart-order-info__address-title">{{ title }}</h3>
			<span class="cart-order-info__address-subtitle">{{ subtitle }}</span>
		</div>
	</div>
</template>
<script>
export default {
	name: "CartOrderInfo",
	props: {
		title: {
			type: String,
			default: "Точка доставки"
		},
		subtitle: {
			type: String,
			default: ""
		}
	}
}
</script>
<style lang="scss">
.cart-order-info
{
	display: flex;
	align-items: flex-start;
	width: 100%;
	gap: 12px;
	background-color: $greyBackground;
	border-radius: 16px;
	padding: 16px;
}

.cart-order-info__address
{
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.cart-order-info__address-title
{
	font-family: $mainFontMediumNew;
	color: $textColor;
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
}

.cart-order-info__address-subtitle
{
	font-family: $mainFontNew;
	color: $greyText;
	font-size: 12px;
	line-height: 16px;
}
</style>