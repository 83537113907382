<template>
	<button
		:class="['ui-button', variant && `ui-button--variant-${variant}`, size && `ui-button--size-${size}`]"
		:disabled="loading || disabled"
		v-on="$listeners"
	>
		<slot v-if="!loading"/>
		<button-preloader v-else/>
	</button>
</template>
<script>
import ButtonPreloader from '../ui/ButtonPreloader.vue';

export default {
	name: "UiButton",
	props: {
		variant: {
			type: String,
			default: "default"
		},
		size: {
			type: String,
			default: "default"
		},
		disabled: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		}
	},
	components: {ButtonPreloader}
}
</script>
<style lang="scss">
.ui-button
{
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	gap: 4px;

	border-radius: 8px;
	border: none;

	transition: .3s;

	&:disabled
	{
		opacity: .5;
		pointer-events: none;
	}
}

.ui-button--variant-default
{
	background-color: $red;
	color: $white;

	&:hover
	{background-color: $redHover;}
}

.ui-button--variant-secondary
{
	background-color: $lightGray;
	color: $textColor;

	&:hover
	{background-color: $lightGrayHover;}
}

.ui-button--variant-text
{
	background-color: transparent;
	color: $greyTextLight;

	&:hover
	{background-color: transparent;}
}

.ui-button--size-default
{
	min-height: 56px;
	padding: 16px 24px;

	font-family: $mainFontBoldNew;
	font-weight: 700;
	font-size: 16px;
	line-height: 150%;
}

.ui-button--size-medium
{
	min-height: 40px;
	padding: 8px 20px;

	font-family: $mainFontBoldNew;
	font-weight: 700;
	font-size: 14px;
	line-height: 24px;
}

.ui-button--size-small
{
	min-height: 24px;
	padding: 0;

	font-family: $mainFontMediumNew;
	font-weight: 500;
	font-size: 14px;
	line-height: 171%;
}
</style>